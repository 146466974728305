






























import { Store, TAwait } from '@/store/store';
import Component from 'vue-class-component';
import TableLoading from '@/components/tables/TableLoading.vue'

@Component({
  components: {
    TableLoading
  }
})
export default class UpcomingInvoice extends Store {
  invoice?: TAwait<ReturnType<typeof Store.prototype.getUpcomingInvoice>> | null = null
  
  fields = [
    {
      key: 'company_name',
      label: 'Company',
    },
    {
      key: 'description',
      label: 'description',
    },
    {
      key: 'period_from',
      label: 'From',
    },
    {
      key: 'period_to',
      label: 'To',
    },
    {
      key: 'days',
      label: 'Days',
    },
    {
      key: 'price',
      label: 'Price',
    },
    {
      key: 'quantity',
      label: 'Quantity',
    },
    {
      key: 'Total',
      label: 'Total',
    },
  ]

  async mounted(){
    this.invoice = await this.getUpcomingInvoice()
  }
}
