

























































































































































import Card from "@/components/cards/Card.vue";
import TabbedCard from '@/components/cards/TabbedCard.vue'
import GenericForm from '@/components/forms/GenericForm.vue'
import { Store, TAwait } from "@/store/store";
import Component from "vue-class-component";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import VuePhoneNumberInput from 'vue-phone-number-input';
import UpcomingInvoice from '@/Distributor/components/UpcomingInvoice.vue'
import CardPayment from '@/components/forms/CardPayment.vue'
import {v4 as uuid} from 'uuid'
interface Tabs {
  id: string,
  title: string,
}

@Component({
  components: {
    Card,
    TabbedCard,
    GenericForm,
    VuePhoneNumberInput,
    UpcomingInvoice,
    CardPayment
  },
  metaInfo: {
    title: 'Billing Details',
  }
})
export default class BillingDetails extends Store {
  componentTitle = 'Billing Details'
  billing: TAwait<ReturnType<typeof Store.prototype.getBillingDetails>> | null = {
    id: 0,
    full_name: '',
    email: '',
    additional_email: '',
    address_line_1: '',
    address_line_2: '',
    town: '',
    country: {
      name: '',
    },
    county_state: '',
    postcode: '',
    phone: '',
    vat_number: '',
  }
  errors = {
    cardErrorMessage: '',
    errorMessage: '',
    full_name: [],
    email: [],
    additional_email: [],
    address_line_1: [],
    address_line_2: [],
    town: [],
    country: [],
    county_state: [],
    postcode: [],
    phone: [],
    vat_number: [],
  }
  successMessage = ''
  cardSuccessMessage = ''

  reloadKey = uuid()

  tabs: Array<Tabs> = [
    {
      id: 'billing',
      title: 'Company Billing Information',
    },
    {
      id: 'invoice',
      title: 'Upcoming Invoice',
    },
    {
      id: 'payment',
      title: 'Payment Method',
    },
  ]

  currentTab = this.tabs[0].id

  showTab(showTab: string) {
    this.currentTab = showTab
  }

  isCurrentTab(thisTab: string): boolean {
    return thisTab === this.currentTab
  }

  async mounted(){
    await this.refresh()
  }

  async refresh(){    
    await this.getCountries()
    this.billing = await this.getBillingDetails().catch(() => {
      
      this.$toast.error('Could Not Get Billing Details')
      return null
    })
    this.reloadKey = uuid()
  }

    
  update() {
    this.clearFormErrors()
    if(this.billing){
      this.updateBillingDetails({ 
        setBillingDetails: {
          full_name: this.billing.full_name,
          email: this.billing.email,
          additional_email: this.billing.additional_email,
          phone: this.billing.phone,
          address_line_1: this.billing.address_line_1,
          address_line_2: this.billing.address_line_2,
          town: this.billing.town ?  this.billing.town : '',
          county_state: this.billing.county_state,
          postcode: this.billing.postcode,
          vat_number: this.billing.vat_number,
          country_id: this.billing.country.id ? +this.billing.country.id : 0
        }
      })
      .then(() => {
        this.successMessage = "The Billing Details have been updated"
        this.$toast.success('Updated Billing Details')
        this.refresh()
      })
      .catch((res) => {
        this.mapFormErrors(res)
        this.$toast.error('Failed To Update Biliing Details')
      })
    }
    // }
    // else {
    //   this.$toast.error('Fill In Required Fields')
    // }
  }

  /**
   * @arg {{formatInternational: string}} code
   * @vuese
   */
  phoneNumberUpdated(code: {formatInternational: string}){
    
    if(this.billing){
      this.billing.phone = code.formatInternational // Format into +XX XXXXXXXXXX format
    }
  }

  updateCard(d: any){
    this.clearFormErrors()
    this.updatePaymentMethod({
      stripe_secret: d.secret,
      card: d.el
    }).then(() => {
      this.cardSuccessMessage = 'Successfully Updated Payment Method'
      this.$toast.success('Successfully Updated Payment Method')
      this.refresh()
    }).catch((er) => {
      this.errors.cardErrorMessage = 'Failed To Update Card'
      this.$toast.error('Failed To Update Card')
    })
  }

  mapFormErrors(formErrors: any) {
    
    this.errors.errorMessage = formErrors.message
    // Profile
    this.errors.full_name = formErrors.errors["full_name"] && formErrors.errors["full_name"].length ? formErrors.errors["full_name"] : []
    this.errors.phone = formErrors.errors["phone"] && formErrors.errors["phone"].length ? formErrors.errors["phone"] : []
    this.errors.vat_number = formErrors.errors["vat_number"] && formErrors.errors["vat_number"].length ? formErrors.errors["vat_number"] : []
    this.errors.email = formErrors.errors["email"] && formErrors.errors["email"].length ? formErrors.errors["email"] : []
    this.errors.additional_email = formErrors.errors["additional_email"] && formErrors.errors["additional_email"].length ? formErrors.errors["additional_email"] : []
    this.errors.address_line_1 = formErrors.errors["address_line_1"] && formErrors.errors["address_line_1"].length ? formErrors.errors["address_line_1"] : []
    this.errors.address_line_2 = formErrors.errors["address_line_2"] && formErrors.errors["address_line_2"].length ? formErrors.errors["address_line_2"] : []
    this.errors.town = formErrors.errors["town"] && formErrors.errors["town"].length ? formErrors.errors["town"] : []
    this.errors.county_state = formErrors.errors["county_state"] && formErrors.errors["county_state"].length ? formErrors.errors["county_state"] : []
    this.errors.postcode = formErrors.errors["postcode"] && formErrors.errors["postcode"].length ? formErrors.errors["postcode"] : []
    this.errors.country = formErrors.errors["country"] && formErrors.errors["country"].length ? formErrors.errors["country"] : []
  }

   /**
   * Clears all form errors from form
   * @vuese
   */
  clearFormErrors(){
    // Card
    this.cardSuccessMessage = ''
    this.errors.cardErrorMessage = ''

    // Billing
    this.successMessage = ''
    this.errors.errorMessage = ''
    // Profile
    this.errors.full_name = []
    this.errors.phone = []
    this.errors.vat_number = []
    this.errors.email = []
    this.errors.additional_email = []
    this.errors.address_line_1 = []
    this.errors.address_line_2 = []
    this.errors.town = []
    this.errors.county_state = []
    this.errors.postcode = []
    this.errors.country = []
  }
}
